<template>
  <main class="main">
    <header class="section_page">    
        <h1>Tangible Learning Toolkit for Children with Intellectual Disabilities</h1>
        <p class="text-mid-s">Tangify, Academic Project</p>
        <section class="project-overview">
          <p>Intellectual disability (or ID) is a term used when a person has certain limitations in cognitive functioning and skills, including communication, social and self-care skills. For a child, these limitations can cause him/her to develop and learn more slowly or differently than other children.</p>
          <p>We used HCI principle-based approach for design intervention to assist children with such disabilities.</p>          
        </section>
        <div class="banner" alt="project thumbnail">
          <iframe            
            class="vid"
            src="https://www.youtube.com/embed/TIBcp5iKvtY?showinfo=0"
            title="project demo video"
            width="100%"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
          />          
        </div>
    </header>
    <div class="section_page">  
          <section class="project-section " id="tangify-problem">
            <header>
              <h2>User Problem & Market Insufficiency</h2>
              <p class="text-mid">
                Current learning tools in the market are not suited for children
                with intellectual disability
              </p>
            </header>
            <div class="project-text">
              <p>
                Intellectual disability (or ID) is a term used when a person has
                certain limitations in cognitive functioning and skills, including
                communication, social and self-care skills. For a child, these
                limitations can cause them to develop and learn more slowly or
                differently than other children.
              </p>
            </div>
          </section>
          <section class="project-section" id="tangify-stakeholders">
            <header>
              <h2>Users and Stakeholders</h2>
              <p class="text-mid">Kids and Caregivers at Shishu Sarothi</p>
            </header>
            <div class="project-text">
              <p>
                <a rel="noopener noreferrer" href="https://shishusarothi.org/">Shishu Sarothi</a> is a
                not-for-profit organization working with children with cognitive
                developmental disabilities, providing all round special educational
                and therapeutic inputs. Run on the lines of a school, yet there is
                no “one size fits all” approach to education for these children.
              </p>
              <p>
                At Shishu Sarothi,Individualized Education Program (IEP) are drawn
                up for each child in to work towards individualized goals.
              </p>
              </div>
            </section>
            <section class="project-section" id="tangify-goals">
              <header>
                <h2>Design Goals</h2>
                <p class="text-mid">Optimized Learning</p>
              </header>
              <div class="project-text">
              <p>
                The trajectory of development of children with IDs are different
                from that of non-disabled children. Based on the severity of the
                disability, the children makes progress at a rate that is
                significantly slower than is expected of children their age.
                Further, for any child with ID, the development of different areas
                (e.g., cognitive abilities, language and speech skills, gross motor
                skills, fine motor skills, social-emotional skills, and play skills)
                may proceed at different rates, on different timelines, and in
                different orders.
              </p>
              <p>
                <strong
                  >Curriculum and instruction must be carefully modified</strong
                >
                to help these students reach their potential in both academics and
                other functional areas such as independent living.
              </p>
            </div>
          </section>
          <section class="project-section" id="tangify-users">
            <header>
              <h2>Understanding our users</h2>
              <p class="text-mid">
                Interviews, Contextual Inquiries, Secondary Research, Artifact
                analysis
              </p>
            </header>
            <div class="project-text">
              <p>
                Being new to these user group, we started with a unstructured
                interviews with different stakeholders of Shishu Sarothi (Director,
                psychologists, instructors, parents) to better understand them.
              </p>
              <p>
                We spent a whole day there to observe and interact with the
                children, parents, caregivers and instructors.
              </p>
              <p>
                Shishu Sarothi groups the children into different units based on the
                severity of ID and their current development stage.
              </p>
              <p>
                Shishu Sarothi groups the children into different units based on the
                severity of ID and their current development stage.
              </p>
              <img src="@/assets/images/projects/tangify/profile.png" />
              <p>
                Being an unique target user group, we started a more formal research
                into this domain. We did internet research to dive into
                understanding our users.
              </p>
              <p>
                Being an unique target user group, we started a more formal research
                into this domain. We did internet research to dive into
                understanding our users.
              </p>
              <img src="@/assets/images/projects/tangify/research.png" />
              <p>
                We did a silent observation of the proceedings of a class - we sat
                in the class the the children learnt their abcs, numbers, objects
                and music.
              </p>
              <p>
                We conducted contextual inquiry to understand the issues specific to
                this group. We used a semi-structured questionnaire to interview 3
                teachers, and a psychologist. We interacted with the kids, and had
                open-ended discussions with the volunteers, and a few parents who
                visited the children at lunch time.
              </p>
              <p>
                We observed props were used extensively by the teachers to teach the
                students. In fact, that was the primary way to drill concepts into
                the children. So, we conducted an artifact analysis of the various
                flash cards, lego, objects, peg boards, dolls, etc. currently used
                to understand the type of objects the kids are familiar with, and
                the order and way in which they were introduced to the kids.
              </p>
              <p>
                Literature review was done to understand previous interventions in
                this domain.
              </p>
            </div>
          </section>
          <section class="project-section" id="tangify-findings">
            <header>
              <h2>Key Findings</h2>
              <p class="text-mid">Hands-on and play-based learning with lots of repetition</p>
            </header>
            <div class="project-text">
              <img src="@/assets/images/projects/tangify/findings.png" />
              <p></p>
            </div>
          </section>
          <section class="project-section" id="tangify-ideation">
            <header>
              <h2>Ideation</h2>
              <p class="text-mid">
                How do we enhance the learning curve of the children without
                increasing the instructor’s efforts?
              </p>
            </header>
            <div class="project-text">
              <p>
                We started the process with a Brainwriting session. From the wide
                set of ideas, we did an affinity analysis to cluster and merge
                ideas.
              </p>
              <p>
                Of these ideas, we did a heatmap voting to choose the idea to move
                forward with. The winner was Tangify.
              </p>
            </div>
          </section>
          <section class="project-section" id="tangify-solution">
            <header>
              <h2>Design & Prototype</h2>
              <p class="text-mid">
                A scalable play-based tool to engage children in learning without
                much requirement of supervision
              </p>
            </header>
            <div class="project-text">
              <p>
                Tangify consists of a
                <strong> compartmentalized platform </strong> to hold
                <strong> of everyday objects</strong>. The platform is
                <strong>
                  connected to a computer to provide the audio and visual cues. The
                  compartments can be mapped to properties of the token. When the
                  tokens are placed in slots of the platform, the computer gives
                  audio-visual output about that particular property of the
                  token</strong
                >.
              </p>
              <img src="@/assets/images/projects/tangify/prototype.jpg" />
              <p>
                Tangify consists of a compartmentalized platform to hold tokens of
                everyday objects. The platform is connected to a computer to provide
                the audio and visual cues. The compartments can be mapped to
                properties of the token. When the tokens are placed in slots of the
                platform, the computer gives audio-visual output about that
                particular property of the token.
              </p>
              <vue-media
                type="video"
                src="https://www.youtube.com/embed/TIBcp5iKvtY?showinfo=0"
                title="Tangify Prototype"
              />
              <p>
                Tangify consists of a compartmentalized platform to hold tokens of
                everyday objects. The platform is connected to a computer to provide
                the audio and visual cues. The compartments can be mapped to
                properties of the token. When the tokens are placed in slots of the
                platform, the computer gives audio-visual output about that
                particular property of the token.
              </p>
            </div>
          </section>
          <section class="project-section" id="tangify-testing">
            <header>
              <h2>Usability Testing</h2>
              <p class="text-mid">Engaging and low learning-curve, but can be better</p>
            </header>
            <div class="project-text">
              <p>
                The first round of user testing was carried out at Shishu Sarothi
                with 8 kids and 1 teacher. The prototype was set up in the classroom
                and the children were given a basic demonstration, and then allowed
                to interact freely as per their understanding. The children
                interacted with the system individually, one by one, and the teacher
                assisted them whenever required.
              </p>
              <img src="@/assets/images/projects/tangify/testing.jpg" />
              <p>
                The results were quite encouraging, as most of the children were
                able to figure out how the system is supposed to be used. The kids
                enjoyed the animation on the screen. They were self-motivated to
                keep using the system and didn't require urging by the teachers.
              </p>
              <p>
                Some feedback we received from the teacher were to avoid using
                multiple colors in the interface, specifically colors that may
                confuse kids with the colors of the fruit. Secondly, we need to
                increase the size of the compartments to accommodate children with
                weak motor skills.
              </p>
              <p>
                In the next phase of testing, we plan to incorporate received
                feedback and test it over a longer time to see if there is any
                change in the learning curve of the children.
              </p>
            </div>
          </section>
    </div>
  </main>
</template>

<script>
export default {

}
</script>

<style>
.vid {
  aspect-ratio: 16 / 9;
}
</style>